// vendor
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";

// source
import SearchIcon from "components/icons/search";
import SearchInput from "./SearchInput";
import { StyledInput } from "components/fields/StyledInput";
import { DialogHelp } from "components/dialogs/DialogHelp";
import useStyles from "./useStyles";

export default function FaqsSidebar({
  catRef,
  setCatRef,
  categories,
  onSearch,
  searchPages,
  setSearchPages,
  searchInputRef,
}) {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <aside>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SearchIcon />
        <Typography variant="h2" mx={2}>
          {t("faqs.page.title")}
        </Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <SearchInput
          ref={searchInputRef}
          pages={searchPages}
          setPages={setSearchPages}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "none",
          [theme.breakpoints.up("md")]: {
            display: "block",
          },
        })}
      >
        <nav className={styles.drawer}>
          <ul className={styles.categories}>
            {categories.map((cat) => (
              <li
                id={"cat_" + cat.ref}
                key={cat.ref}
                onClick={() => {
                  setSearchPages();
                  setCatRef(cat.ref);
                }}
                className={[
                  styles.category,
                  "cat",
                  catRef == cat.ref && !onSearch ? "current" : "",
                ].join(" ")}
              >
                <Typography style={{ /* for-wp */ marginBottom: 0 }}>
                  {cat.name}
                </Typography>
              </li>
            ))}
          </ul>
        </nav>
      </Box>
      <Box
        sx={(theme) => ({
          mt: 8,
          display: "block",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      >
        <InputLabel
          id="cat-menu-label"
          label={"Selecciona"}
          questionLabel={"Selecciona"}
        />
        <Select
          onChange={({ target }) => setCatRef(target.value)}
          labelId="cat-menu-label"
          input={<StyledInput />}
          disableUnderline
          fullWidth
          defaultValue={categories[0]?.ref || ""}
          value={catRef}
        >
          {categories.map((cat) => (
            <MenuItem
              sx={(theme) => ({
                "&.Mui-selected": {
                  "backgroundColor": theme.palette.grey[900],
                  "color": theme.palette.grey[100],
                  "&:hover": { backgroundColor: theme.palette.grey[800] },
                },
                "&:hover": { backgroundColor: theme.palette.grey[200] },
              })}
              key={cat.ref}
              value={cat.ref}
            >
              {cat.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={(theme) => ({
          display: "none",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            display: "flex",
            pl: 8,
          },
        })}
      >
        <DialogHelp origin="faqs" />
      </Box>
    </aside>
  );
}
