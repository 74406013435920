import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "components/Button";
import { getLoginUrl, getSignupUrl, openUrl } from "lib/helpers/urls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";

export const RoleSelector = ({
  lines,
  isFiberAssociated,
  isPromotionActive,
  coop_agreement_code,
  sx,
  buttonsLayout,
}) => {
  const { is_company } = useQueryParams();
  const isCompany = is_company === "true";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  function onWantsToBeSponsoredClick() {
    openUrl(
      getSignupUrl(
        lines,
        "sponsored",
        undefined,
        true,
        isFiberAssociated,
        false,
        false,
        isCompany
      ),
      "_parent"
    );
  }

  function onWantsToBeMemberClick() {
    openUrl(
      getSignupUrl(
        lines,
        "coop_agreement",
        isPromotionActive ? coop_agreement_code : "SC",
        true,
        isFiberAssociated,
        false,
        isPromotionActive,
        isCompany
      ),
      "_parent"
    );
  }

  function onAlreadyUserClick() {
    openUrl(
      getLoginUrl(lines, isFiberAssociated, isPromotionActive, isCompany)
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: isMobile ? "16px 16px 24px 16px" : "24px",
        ...sx,
      }}
    >
      <Typography variant={isMobile ? "body" : "h4"}>
        {t("funnel.tariffs.modal_contract.select_contract")}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {!isPromotionActive && (
          <Button
            size={"normal"}
            onClick={onWantsToBeSponsoredClick}
            variant="transparent"
            sx={{ textTransform: "none" }}
          >
            {t("funnel.tariffs.modal_contract.sponsored")}
          </Button>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: buttonsLayout,
            gap: 1,
          }}
        >
          <Button
            size={"normal"}
            variant="secondary"
            endIcon={<ArrowForwardIcon />}
            sx={{ textTransform: "none" }}
            onClick={onAlreadyUserClick}
            fullWidth
          >
            {t("funnel.tariffs.modal_contract.already_user")}
          </Button>
          <Button
            size={"normal"}
            endIcon={<ArrowForwardIcon />}
            sx={{ textTransform: "none" }}
            onClick={onWantsToBeMemberClick}
            fullWidth
          >
            {t("funnel.tariffs.modal_contract.new_user")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
