import { TopBar } from "components/TopBar";
import { styled, Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { SessionListener } from "components/modals/SessionListener";
import { Data } from "./Data";
import { Payment } from "./Payment";
import { Confirm } from "./Confirm";
import { Thanks } from "./Thanks";
import { PaymentSummary } from "./PaymentSummary";
import { useCatalog } from "hooks/queries/useTariffs";
import { useApplicationContext } from "hooks/useApplicationContext";
import { reusePartnerAddresses } from "lib/domain/somconnexio/reuseAddresses";
import { buildAnalyticsParamFromState } from "./shared/buildAnalyticsParamFromState";
import { useStore } from "hooks/useStore";
import { compact, uniq, uniqueId } from "lodash";
import { useQueryParam, StringParam, JsonParam } from "use-query-params";
import { getFiberContracts } from "lib/api/fiberContracts";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useContracts } from "hooks/queries/useContracts";
import { Stepper } from "components/Stepper";
import { Forest } from "components/icons/Forest";

const Wrapper = styled("div")(({ theme, isMobile, hasSidebar }) => ({
  background: theme.palette.secondary.main,
  width: "100%",
  padding: isMobile ? "24px 16px" : "48px 64px",
  paddingRight: isMobile ? 16 : hasSidebar ? 420 : 0,
  borderBottom: hasSidebar ? `35px solid ${theme.palette.grey[900]}` : "",
  position: "relative",
}));

export const SignUp = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [intent] = useQueryParam("intent", JsonParam);
  const state = useStore((state) => state);
  const [tariffs, setTariffs] = useState([]);
  const isCompany = intent ? !!intent.isCompany : state.isCompany;
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const setFiberLinesToAssociateMobile = useStore(
    (state) => state.setFiberLinesToAssociateMobile
  );
  const { internetLineInFormData } = useLinesFormDerivedState();
  const iconFooterWidth = isMobile ? "357px" : "625px";

  const steps = ["/signup/data", "/signup/payment", "/signup/confirm"];
  const shouldShowStepper = steps.includes(pathname);
  const shouldShowPaymentSummary = shouldShowStepper;
  useEffect(() => {
    async function fetchFiberLinesToAssociateMobile() {
      try {
        setFiberLinesToAssociateMobile(await getFiberContracts());
      } catch (err) {
        return;
      }
    }
    if (loggedIn && !Boolean(internetLineInFormData)) {
      fetchFiberLinesToAssociateMobile();
    }
  }, [loggedIn, internetLineInFormData]);

  const { isLoading: isCatalogLoading, data: catalog } = useCatalog({
    isCompany,
  });
  useEffect(() => {
    if (isCatalogLoading) return;

    if (intent.isFiberAssociated) {
      const belongsToPack = intent.lines.reduce((packCode, line) => {
        return packCode || line.belongs;
      }, void 0);
      const pack = catalog.packs.find((pack) => pack.code === belongsToPack);
      const packProductCodes = pack.products.map((p) => p.code);
      const filteredTariffs = tariffs.filter(
        (tariff) => packProductCodes.indexOf(tariff.code) === -1
      );
      setTariffs(filteredTariffs.concat(pack.products));
    } else {
      setTariffs(catalog.tariffs);
    }
  }, [isCatalogLoading]);

  const { isLoading: isSubscriptionsLoading, data: subscriptions } =
    useContracts({
      userName: currentUser?.username,
      enabled: loggedIn,
    });
  const saveAddress = useStore((state) => state.saveAddress);
  const setAvailablePaymentMethods = useStore(
    (state) => state.setAvailablePaymentMethods
  );
  const waitingForSubscriptions = loggedIn && isSubscriptionsLoading;
  const isLoading = isCatalogLoading || waitingForSubscriptions;

  const [form, setFormParam] = useQueryParam("form", StringParam);

  useEffect(() => {
    function initializeAddresses() {
      reusePartnerAddresses(compact(currentUser.profile_addresses)).forEach(
        (address) => saveAddress(address)
      );
    }

    function initializePaymentMethods() {
      setAvailablePaymentMethods(
        uniq(subscriptions.data.map(({ iban }) => iban)).map((iban) => ({
          id: uniqueId(),
          type: "bank-account",
          iban,
        }))
      );
    }

    if (!loggedIn || isSubscriptionsLoading) {
      return;
    }

    initializeAddresses();
    initializePaymentMethods();
  }, [loggedIn, isSubscriptionsLoading, saveAddress, subscriptions]);

  useEffect(() => {
    const currentStep = (pathname.split("/") || [])[2] || "";
    setFormParam(buildAnalyticsParamFromState(state, currentStep));
  }, [state]);

  return (
    <TopBar>
      <Wrapper hasSidebar={shouldShowPaymentSummary} isMobile={isMobile}>
        {isLoading ? (
          <Box sx={{ height: "83vh", display: "flex" }}>
            <Spinner />
          </Box>
        ) : (
          <>
            {shouldShowStepper && (
              <Box mt={isMobile && 9} mb={isMobile ? 1 : 8}>
                <Stepper
                  currentStep={pathname}
                  title={"funnel.header.title"}
                  steps={steps.map((step) => ({
                    key: step,
                    label: "funnel.header.steps." + step.split("/")[2],
                  }))}
                />
              </Box>
            )}
            <Switch>
              <Route path={`${path}/data`}>
                <Data tariffs={tariffs} />
              </Route>
              <Route path={`${path}/payment`}>
                <Payment />
              </Route>
              <Route path={`${path}/confirm`}>
                <Confirm tariffs={tariffs} />
              </Route>
              <Route path={`${path}/thanks`}>
                <Thanks />
              </Route>
            </Switch>
            {shouldShowPaymentSummary && (
              <>
                <PaymentSummary tariffs={tariffs} />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: isMobile ? "-10px" : "-13px",
                    left: `calc((100vw - ${iconFooterWidth} - ${
                      isMobile ? "0px" : "356px"
                    })/2)`,
                  }}
                >
                  <Forest
                    width={iconFooterWidth}
                    height={isMobile ? "96px" : "168px"}
                  />
                </Box>
              </>
            )}
            <SessionListener isLoggedIn={loggedIn} />
          </>
        )}
      </Wrapper>
    </TopBar>
  );
};
