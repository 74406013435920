import { Box, useMediaQuery } from "@mui/material";
import {
  Mobile,
  MobileAssociatedFiber,
  Router,
} from "components/icons/Products";
import { Carousel } from "components/layouts/Carousel";
import { TariffCard } from "./TariffCard";

export const TariffCards = ({ getTo = (to) => `/tariffs/${to}` }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const Cards = [
    <TariffCard
      icon={<Mobile />}
      title={"funnel.join.common.mobile"}
      checkLiterals={[
        "funnel.join.common.checks.mobile.first",
        "funnel.join.common.checks.mobile.second",
        "funnel.join.common.checks.mobile.third",
      ]}
      buttonVariant={"outlined"}
      to={getTo("mobile")}
    />,
    <TariffCard
      icon={<MobileAssociatedFiber />}
      title={"funnel.join.common.both"}
      checkLiterals={[
        "funnel.join.common.checks.both.first",
        "funnel.join.common.checks.both.second",
        "funnel.join.common.checks.both.third",
      ]}
      buttonVariant={"primary"}
      to={getTo("internet_and_mobile")}
    />,
    <TariffCard
      icon={<Router />}
      title={"funnel.join.common.internet"}
      checkLiterals={[
        "funnel.join.common.checks.internet.first",
        "funnel.join.common.checks.internet.second",
        "funnel.join.common.checks.internet.third",
      ]}
      buttonVariant={"outlined"}
      to={getTo("internet")}
    />,
  ];
  return (
    <>
      {isMobile ? (
        <Box sx={{ mx: "-16px" }}>
          <Carousel slides={Cards} />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 5 }}>
          {Cards.map((tariffCard, index) => (
            <div key={`card_${index}`}>{tariffCard}</div>
          ))}
        </Box>
      )}
    </>
  );
};
