import { Box } from "@mui/material";
import { MobileTariffPicker } from "../components/mobile/MobileTariffPicker";
import { useStore } from "hooks/useStore";
import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { AddMobileButton } from "../components/mobile/AddMobileButton";
import { PlusIcon } from "components/icons/PlusIcon";
import { DialogHelp } from "components/dialogs/DialogHelp";
import { typeToCollection } from "lib/helpers/urls";
import { useMemo } from "react";

export const MobileTariffs = ({ tariffs }) => {
  const {
    addMobileLine,
    updateLineAt,
    idMobileTariffInOffer,
    setIdMobileTariffInOffer,
  } = useStore();
  const {
    mobileLines,
    internetLines,
    hasEmptyLines,
    canShowMobileTariffInOffer,
  } = useLinesDerivedState();

  const mobileTariffs = useMemo(() => {
    return tariffs.filter((tariff) => tariff.category === "mobile");
  }, [tariffs]);

  function onChange(matchedTariff, index, line__id) {
    if (matchedTariff) {
      updateLineAt(matchedTariff, "mobile", index);
      if (matchedTariff?.is_offer_tariff) {
        setIdMobileTariffInOffer(line__id);
      } else if (idMobileTariffInOffer === line__id) {
        setIdMobileTariffInOffer(-1);
      }
    } else {
      updateLineAt(typeToCollection("mobile"), "mobile", index);
    }
  }

  return (
    <>
      {mobileLines.map((line, index) => (
        <Box key={line.__id} sx={{ width: "100%" }}>
          {index > 0 && (
            <Box sx={{ my: 5, display: "flex", justifyContent: "center" }}>
              <PlusIcon />
            </Box>
          )}
          <MobileTariffPicker
            mobileTariffs={mobileTariffs}
            tariffsInOffer={canShowMobileTariffInOffer(line.__id)}
            onChange={(matchedTariff) =>
              onChange(matchedTariff, internetLines.length + index, line.__id)
            }
            initialTariffCode={line?.code}
          />
        </Box>
      ))}
      {mobileLines.length < 4 && !hasEmptyLines && (
        <Box
          sx={{
            mt: 5,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AddMobileButton onClick={addMobileLine} />
        </Box>
      )}
      {!hasEmptyLines && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <DialogHelp origin="tariffs" />
        </Box>
      )}
    </>
  );
};
