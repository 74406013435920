import axios from "axios";

export const getTariffs = async ({
  code = "21IVA",
  is_company = false,
} = {}) => {
  const { data } = await axios.get(`/product-catalog/`, {
    params: { code, is_company },
  });

  try {
    return data[0];
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};
