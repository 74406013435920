import create from "zustand";
import { devtools } from "zustand/middleware";
import { partition, uniqBy, compact } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  showPayMemberFee,
  mustPayMonthlyBill,
  mustSelectRole,
  mustShipmentSimCard,
} from "./useDerivedState";

const personalDataSteps = ["partner/personal-data", "partner/additional-data"];

const stampLineId = (line) => {
  if (line.__id) {
    return line;
  }

  return {
    __id: uuidv4(),
    ...line,
  };
};

const computeSteps = ({
  lines,
  loggedIn,
  optingForRole,
  formStepDataByKey,
  coopAgreementCode,
}) => {
  const lineSteps = lines.flatMap((line) => [
    `line-${line.__id}/tariff`,
    `line-${line.__id}/additional-data`,
  ]);

  return [
    ...(loggedIn ? [] : personalDataSteps),
    ...lineSteps,
    ...compact([
      mustPayMonthlyBill({ lines }) && "payment/monthly-bill",
      mustShipmentSimCard({ formStepDataByKey }) && "payment/shipment",
      showPayMemberFee({ optingForRole, loggedIn }) && "payment/member-fee",
      mustSelectRole({ optingForRole, loggedIn, coopAgreementCode }) &&
        "payment/select-role",
    ]),
  ];
};

const initTariffsToStepData = ({ allowedLines }) => {
  const tariffsFormStepData = {};
  allowedLines.forEach((allowedLine) => {
    tariffsFormStepData[`line-${allowedLine.__id}/tariff`] = allowedLine;
  });
  return tariffsFormStepData;
};

const initHasLandlinePhone = ({ allowedLines }) => {
  const allowedInternetLine = allowedLines.filter(
    (allowedLine) => allowedLine.type === "internet"
  );
  if (allowedInternetLine.length > 0) {
    return allowedInternetLine[0]?.has_landline_phone;
  }
  return undefined;
};

const initInternetTechnology = ({ allowedLines }) => {
  const allowedInternetLine = allowedLines.filter(
    (allowedLine) => allowedLine.type === "internet"
  );
  if (allowedInternetLine.length > 0) {
    return allowedInternetLine[0]?.category;
  }
  return undefined;
};

// TODO add action
//
// - removeTariffAt
// - adding/removing tariffs should affect steps
//
// TODO add derived state
//
// - canAddMobileLine
// - canAddInternetLine
export const useStore = create(
  devtools((set, get) => ({
    sessionExpired: false,
    setSessionExpired: () => set(() => ({ sessionExpired: true })),
    currentIndex: 0,
    setCurrentIndex: (currentIndex) => set(() => ({ currentIndex })),
    hasLandlinePhone: null,
    setHasLandlinePhone: (hasLandlinePhone) =>
      set(() => ({ hasLandlinePhone })),
    internetTechnology: null,
    setInternetTechnology: (internetTechnology) =>
      set(() => ({ internetTechnology })),
    fiberLinesToAssociateMobile: [],
    setFiberLinesToAssociateMobile: (fiberLinesToAssociateMobile) =>
      set(() => ({ fiberLinesToAssociateMobile })),
    idMobileTariffInOffer: -1,
    setIdMobileTariffInOffer: (line__id) =>
      set(() => ({ idMobileTariffInOffer: line__id })),
    formStepDataByKey: {},
    steps: [],
    rejectedLines: [],
    loggedIn: null,
    currentRole: null,
    optingForRole: null,
    coopAgreementCode: null,
    initialIntent: null,
    resetSignupFlow: () => {
      set({
        currentRole: null,
        optingForRole: null,
        coopAgreementCode: null,
        lines: [],
        formStepDataByKey: {},
        hasLandlinePhone: null,
        internetTechnology: null,
        currentIndex: 0,
        rejectedLines: [],
        steps: [],
        initialIntent: null,
        isFiberAssociated: false,
        isPromotionActive: false,
        isCompany: false,
      });
    },
    initializeSignupFlow: ({
      loggedIn,
      currentRole,
      optingForRole,
      coopAgreementCode,
      lines,
      tariffs,
      initialIntent,
      isFiberAssociated,
      isPromotionActive,
      isCompany,
    }) => {
      const findTariffByCode = (code) =>
        tariffs.find((tariff) => tariff.code === code);

      const findMobileTariffByMinutesAndData = (minutes, data) =>
        tariffs.find(
          (tariff) => tariff.minutes === minutes && tariff.data === data
        );

      let [allowedLines, rejectedLines] = partition(lines, (line) => {
        if (!Boolean(line.code)) {
          return true;
        }

        if (line?.is_offer_tariff) {
          return (
            findMobileTariffByMinutesAndData(line.minutes, line.data)
              ?.available_for || []
          ).includes(currentRole || optingForRole);
        }

        return (findTariffByCode(line.code)?.available_for || []).includes(
          currentRole || optingForRole
        );
      });

      allowedLines = allowedLines.map(stampLineId);
      const initialFormSteps = initTariffsToStepData({ allowedLines });

      return set({
        currentRole,
        optingForRole,
        coopAgreementCode,
        loggedIn,
        lines: allowedLines,
        formStepDataByKey: initialFormSteps,
        hasLandlinePhone: initHasLandlinePhone({ allowedLines }),
        internetTechnology: initInternetTechnology({ allowedLines }),
        currentIndex: loggedIn ? 1 : 0,
        rejectedLines,
        initialIntent,
        steps: computeSteps({
          lines: allowedLines,
          loggedIn,
          optingForRole,
          formStepDataByKey: initialFormSteps,
          coopAgreementCode,
        }),
        isFiberAssociated,
        isPromotionActive,
        isCompany,
      });
    },
    setCurrentStep: (step) =>
      set((state) => ({ currentIndex: state.steps.indexOf(step) })),
    gotoNextStep: () =>
      set((state) => ({ currentIndex: state.currentIndex + 1 })),
    setOptingRole: (role) => set((state) => ({ optingForRole: role })),
    setCoopAgreementCode: (coopAgreement) =>
      set((state) => ({ coopAgreementCode: coopAgreement })),
    calculateNextSteptoGo: () =>
      set((state) => {
        const nextIndex = state.currentIndex + 1;
        if (state.steps.length > nextIndex) {
          const nextStep = state.steps[nextIndex];

          if (!nextStep.split("/")[0].startsWith("line")) {
            state.gotoNextStep();
            return;
          }
          const lineStepsWithData = Object.entries(
            state.formStepDataByKey
          ).filter(([key, value]) => key.startsWith("line") && value?.code);
          const currentStep = state.steps[state.currentIndex];

          return {
            currentIndex:
              state.currentIndex +
              (lineStepsWithData.filter(([key]) => key === nextStep).length >
                0 && !currentStep.endsWith("/tariff")
                ? 2
                : 1),
          };
        }

        return { currentIndex: null };
      }),

    lines: [],
    setLines: (lines) => set(() => ({ lines: lines.map(stampLineId) })),
    isFiberAssociated: false,
    setIsFiberAssociated: (type) => set(() => ({ isFiberAssociated: type })),
    selectedPackCode: undefined,
    setSelectedPackCode: (code) => set(() => ({ selectedPackCode: code })),

    isPromotionActive: false,
    setIsPromotionActive: (isPromoActive) =>
      set(() => ({ isPromotionActive: isPromoActive })),

    isCompany: false,
    availableAddresses: [],
    availablePaymentMethods: [],

    saveAddress: (address) =>
      set((state) => {
        if (!address?.street) {
          return {};
        }

        const newAddress = { ...address };

        if (!newAddress._id) {
          newAddress._id = uuidv4();
        }

        return {
          availableAddresses: uniqBy(
            [...state.availableAddresses, newAddress],
            "_id"
          ),
        };
      }),

    savePaymentMethod: (paymentMethod) =>
      set((state) => {
        return state;
      }),

    setAvailableAddresses: (availableAddresses) =>
      set(() => ({ availableAddresses })),

    setAvailablePaymentMethods: (availablePaymentMethods) =>
      set(() => ({ availablePaymentMethods })),

    setFormStepData: (key, data) =>
      set((state) => ({
        formStepDataByKey: {
          ...state.formStepDataByKey,
          [key]: data,
        },
      })),

    /**
     * Internet lines comes first, so we need to add it before the first mobile
     * line, are append at the end
     *
     */
    addInternetLine: () =>
      set((state) => {
        const lines = state.lines;
        const newLine = stampLineId({ type: "internet" });

        let result = {};

        const insertBeforeIndex = lines.findIndex(
          (line) => line.type === "mobile"
        );

        let nextVal;

        if (insertBeforeIndex === -1) {
          nextVal = [...lines, newLine];
        } else {
          nextVal = [...lines];
          nextVal.splice(insertBeforeIndex, 0, newLine);
        }

        result.lines = nextVal;

        result.steps = computeSteps({
          lines: nextVal,
          loggedIn: state.loggedIn,
          optingForRole: state.optingForRole,
          formStepDataByKey: state.formStepDataByKey,
          coopAgreementCode: state.coopAgreementCode,
        });

        return result;
      }),

    /**
     * Mobile lines are always added at the end
     */
    addMobileLine: () =>
      set((state) => {
        const newLine = stampLineId({ type: "mobile" });

        const result = {
          lines: [...state.lines, newLine],
        };

        result.steps = computeSteps({
          lines: result.lines,
          loggedIn: state.loggedIn,
          optingForRole: state.optingForRole,
          formStepDataByKey: state.formStepDataByKey,
          coopAgreementCode: state.coopAgreementCode,
        });

        return result;
      }),

    updateLineAt: (tariff, type, index) =>
      set((state) => {
        const nextVal = [...state.lines];
        const __id = state.lines[index].__id;

        nextVal.splice(index, 1, { __id, type, ...tariff });

        return { lines: nextVal };
      }),

    checkShipmentSimCard: () =>
      set((state) => {
        const result = {};
        result.steps = computeSteps({
          lines: state.lines,
          loggedIn: state.loggedIn,
          optingForRole: state.optingForRole,
          formStepDataByKey: state.formStepDataByKey,
          coopAgreementCode: state.coopAgreementCode,
        });
        return result;
      }),
    removeLine: (id, type) =>
      set((state) => {
        const nextVal = state.lines.filter((line) => line.__id !== id);
        const result = { lines: nextVal };
        /*
         check if deleted line is the mobile tariff in offer
         */
        if (id === state.idMobileTariffInOffer)
          result.idMobileTariffInOffer = -1;

        let nextCurrentIndex = state.currentIndex;

        result.steps = computeSteps({
          lines: result.lines,
          loggedIn: state.loggedIn,
          optingForRole: state.optingForRole,
          formStepDataByKey: state.formStepDataByKey,
          coopAgreementCode: state.coopAgreementCode,
        });
        /*
         If currentIndex is the removed line (tariff || aditional-data) 
         or is previous step 
         then recalculate index.
         */
        if (
          state.steps
            .filter((step) => step.startsWith(`line-${id}`))
            .some(
              (step) =>
                state.steps.indexOf(step) === state.currentIndex ||
                state.steps.indexOf(step) < state.currentIndex
            )
        ) {
          // Find next line to focus, the last mobile tariff in form
          const nextLineToFocus = nextVal
            .filter((line) => line.type === type)
            .pop();
          // Find next step to focus after steps are recomputed
          nextCurrentIndex = result.steps.findIndex((step) =>
            step.startsWith(`line-${nextLineToFocus.__id}`)
          );
        }
        result.currentIndex = nextCurrentIndex;
        return result;
      }),
  }))
);
