import { useStore } from "hooks/useStore";
import { PacksTariffPicker } from "../components/packs/PacksTariffPicker";

export const PackTariffs = ({ packs, ...props }) => {
  const {
    setLines,
    setIsFiberAssociated,
    selectedPackCode,
    setSelectedPackCode,
  } = useStore();
  function onChange(matchedPack) {
    if (matchedPack) {
      setIsFiberAssociated(true);
      setSelectedPackCode(matchedPack.code);
      setLines(matchedPack.products);
    } else {
      setSelectedPackCode(undefined);
      setLines([]);
    }
  }
  return (
    <PacksTariffPicker
      initialPackCode={selectedPackCode}
      packs={packs}
      onChange={onChange}
      {...props}
    />
  );
};
