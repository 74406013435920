import { getTariffs } from "lib/api/tariffs";
import { useQuery } from "react-query";
import { decoratePackTarrifs } from "./helper/tariffDecorator";

const useQueryTariffs = (queryKey, decorator, automatically_run, isCompany) =>
  useQuery(
    queryKey,
    () =>
      getTariffs({
        code: isCompany ? "0IVA" : "21IVA",
        is_company: isCompany,
      }).then((pricelist) => (decorator ? decorator(pricelist) : pricelist)),
    {
      enabled: automatically_run,
    }
  );

export const useCatalog = ({
  automatically_run = true,
  isCompany = false,
} = {}) => {
  return useQueryTariffs(
    `catalog_${isCompany ? "company" : "particular"}`,
    ({ products, packs }) => {
      return {
        tariffs: products,
        packs: packs.map(decoratePackTarrifs),
      };
    },
    automatically_run,
    isCompany
  );
};

export const useTariffs = ({ automatically_run = true } = {}) =>
  useQueryTariffs("products", ({ products }) => products, automatically_run);

export const usePackTariffs = ({ automatically_run = true } = {}) =>
  useQueryTariffs(
    "packs",
    ({ packs }) => packs.map(decoratePackTarrifs),
    automatically_run
  );
