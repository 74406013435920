import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { InternetTariffs } from "./InternetTariffs";
import { MobileTariffs } from "./MobileTariffs";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FloatingCard } from "components/layouts/FloatingCard";
import { useEffect, useMemo } from "react";
import { getExtremeMobileRates } from "hooks/queries/helper/catalogMaker";
import { useStore } from "hooks/useStore";
import { typeToCollection } from "lib/helpers/urls";

export const PersonalizedTariffs = ({ tariffs }) => {
  const { t } = useTranslation();
  const { hasInternetLineLoaded, internetLines, mobileLines } =
    useLinesDerivedState();
  const { updateLineAt } = useStore();
  const mobileTariffs = useMemo(() => {
    if (internetLines[0]?.category === "4G") {
      return tariffs.filter((tariff) => tariff.category === "mobile");
    }
    return getExtremeMobileRates(tariffs);
  }, [tariffs, internetLines]);
  useEffect(() => {
    mobileLines.forEach(({ code: lineCode }, index) => {
      if (lineCode && !mobileTariffs.some(({ code }) => code === lineCode)) {
        updateLineAt(typeToCollection("mobile"), "mobile", index + 1);
      }
    });
  }, [mobileTariffs]);
  return (
    <>
      <InternetTariffs tariffs={tariffs} isInfoArrowed={false} />
      {hasInternetLineLoaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "32px",
          }}
        >
          <Box sx={{ width: "100%", mb: 5 }}>
            <FloatingCard>
              <Typography variant="body_bold">
                {t("funnel.tariffs.personalized.add_mobiles")}
              </Typography>
            </FloatingCard>
          </Box>
          <MobileTariffs tariffs={mobileTariffs} />
        </Box>
      )}
    </>
  );
};
