import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckListCard } from "components/CheckListCard";
import { theme } from "theme";
import { Button } from "components/Button";
import { Separator } from "components/Separator";
import { Link } from "components/Link";
import { getUrlQuery } from "lib/helpers/urls";
import { useQueryParams } from "hooks/useQueryParams";

export const TariffCard = ({
  icon,
  title,
  checkLiterals,
  buttonVariant,
  to,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const qp = useQueryParams();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "relative", top: "30px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>{icon}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 6,
          padding: `65px ${isMobile ? "50px" : "60px"} 30px`,
          borderRadius: "16px",
          border: `3px solid ${theme.palette.grey[900]}`,
          background: theme.palette.grey[100],
          height: isMobile ? "550px" : "430px",
          width: isMobile ? "300px" : "360px",
        })}
      >
        <Typography variant="h4">{t(title)}</Typography>
        <Separator />
        <CheckListCard literals={checkLiterals.map((lit) => t(lit))} />
        <Link
          target="_parent"
          to={getUrlQuery(to, qp)}
          showUnderline={false}
          style={{ marginTop: "auto", width: "100%" }}
        >
          <Button variant={buttonVariant} fullWidth>
            {t("funnel.join.common.button")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
