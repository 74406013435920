import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { ModalTariffMoreInfo } from "components/modals/TariffMoreInfo";
import { useMemo, useState } from "react";

export const TariffInfo = ({
  type,
  category,
  hasLandline,
  minutes,
  isSharedLines,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pageRef = useMemo(() => {
    switch (type) {
      case "internet":
        return `${category}${hasLandline ? "_landline_phone" : ""}`;
      case "pack":
        return `pack_${isSharedLines ? "mobiles" : "mobile"}${
          hasLandline ? "_landline_phone" : ""
        }`;
      case "mobile":
        if (minutes !== undefined)
          return `mobile${
            minutes < 99999 ? "_limited_minutes" : "_unlimited_minutes"
          }`;
        return;
      default:
        return;
    }
  }, [category, hasLandline, minutes, isSharedLines, type]);

  return (
    <>
      <ModalTariffMoreInfo
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        pageRef={pageRef}
        {...props}
      />
      <IconButton
        sx={{
          p: 0,
          color: "inherit",
        }}
        onClick={() => setIsModalOpen((isOpen) => !isOpen)}
        disabled={!Boolean(pageRef)}
        aria-label="close_modal"
      >
        <InfoIcon sx={{ fontSize: "1.5rem" }} />
      </IconButton>
    </>
  );
};
