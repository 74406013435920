import { useApplicationContext } from "hooks/useApplicationContext";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { LowerDialog, RightDialog } from "components/icons/Dialogs";
import { HeaderIcon } from "components/icons/JoinIcon";
import { BigCloud } from "components/icons/Clouds";

const Icon = ({ isMobile, isPromotionActive }) => {
  function GetDialogLit() {
    const { t } = useTranslation();
    const prefix = isPromotionActive ? "coop_agreement.promotion" : "anonymous";
    return t(`funnel.join.${prefix}.dialog_lit`);
  }
  return (
    <Box
      sx={{
        position: "relative",
        bottom: "95px",
        left: isMobile ? "0px" : "75px",
        height: isMobile ? "300px" : "425px",
        ml: isMobile ? "0px" : "-75px",
        mb: isMobile ? "-20px" : "-95px",
      }}
    >
      <HeaderIcon
        width={isMobile ? "264" : "364"}
        height={isMobile ? "241" : "341"}
      />
      <Box
        sx={{
          position: "relative",
          left: isMobile ? "42px" : "75px",
          bottom: isMobile ? "58px" : "95px",
        }}
      >
        <LowerDialog
          width={isMobile ? "159" : "278"}
          height={isMobile ? "120" : "203"}
        />
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Rock Salt",
            fontSize: isPromotionActive ? "16px" : "20px",
            fontWeight: "400",
            lineHeight: "22px",
            letterSpacing: "-0.4px",
            position: "relative",
            bottom: isMobile ? (isPromotionActive ? "95px" : "75px") : "110px",
            left: isMobile ? "8px" : "6px",
            maxWidth: isMobile ? "140px" : "260px",
          }}
        >
          {GetDialogLit()}
        </Typography>
      </Box>
    </Box>
  );
};
export const Heading = ({
  optingForRole,
  coopAgreementCode,
  coopAgreement,
  isPromotionActive,
  simpleHeader,
}) => {
  const { currentUser } = useApplicationContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  function PromotionHeading({ optingForRole, coopAgreement }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    return (
      <>
        <Typography variant={isMobile ? "h3" : "h2"} gutterBottom>
          {t(`funnel.join.${optingForRole}.promotion.title`, {
            coopAgreement,
          })}
        </Typography>
        <Box maxWidth={600}>
          <Typography variant="h4" gutterBottom>
            {t(`funnel.join.${optingForRole}.promotion.subtitle`)}
          </Typography>
          <Typography variant="body" paragraph>
            <Trans>
              {t(`funnel.join.${optingForRole}.promotion.text_1`, {
                coopAgreement,
              })}
            </Trans>
          </Typography>
          <Typography variant="body" paragraph>
            <Trans>{t(`funnel.join.${optingForRole}.promotion.text_2`)}</Trans>
          </Typography>
          <Typography variant="body_small" paragraph>
            <Trans i18nKey={"funnel.join.coop_agreement.promotion.text_3"}>
              <Link
                target="_blank"
                to={t("funnel.join.coop_agreement.promotion.terms_url")}
              />
            </Trans>
          </Typography>
        </Box>
        {/* TODO new image  ¿¿??*/}
        {/* <Box display="flex" justifyContent="center">
            <img
              height={"350px"}
              src={`/intercooperationAgreements_${i18n.language}.png`}
              alt={"The best welcome to the best service"}
            />
          </Box> */}
      </>
    );
  }
  function PublicHeading({ optingForRole, coopAgreementCode, coopAgreement }) {
    const { t } = useTranslation();
    const isOptingForRole = Boolean(optingForRole);

    function resolveLiterals() {
      if (isOptingForRole) {
        // is deprecated literals to optingForRole = coop_agreement & coopAgreementCode = SC
        // return optingForRole === "coop_agreement" && coopAgreementCode === "SC"
        //   ? "self_agreement"
        //   : optingForRole;
        return optingForRole;
      }
      return "anonymous";
    }

    const roleToLiterals = resolveLiterals();

    return (
      <>
        {!isOptingForRole && (
          <Typography variant="body_bold" sx={{ mb: 6 }}>
            {t("funnel.join.anonymous.associate")}
          </Typography>
        )}
        <Typography variant={isMobile ? "h3" : "h2"} gutterBottom>
          {t(`funnel.join.${roleToLiterals}.title`, {
            coopAgreement,
          })}
        </Typography>
        <Typography variant="h4" gutterBottom>
          {t(`funnel.join.${roleToLiterals}.subtitle`)}
        </Typography>
        <Typography variant="body" paragraph>
          {t(`funnel.join.${roleToLiterals}.text_1`, {
            coopAgreement,
          })}
        </Typography>
        <Typography variant="body_bold" paragraph>
          {t(`funnel.join.${roleToLiterals}.text_2`)}
        </Typography>
      </>
    );
  }
  function SimpleHeading() {
    const { t } = useTranslation();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: isMobile ? 0 : "-30px",
        }}
      >
        <Typography variant="body_bold" sx={{ mb: 6 }}>
          {t("common.tariffs")}
        </Typography>
        {isMobile ? (
          <Typography variant={"h3"} sx={{ textAlign: "center" }}>
            {t(
              `funnel.join.${
                currentUser ? "authenticated" : "simple_header"
              }.title`
            )}
          </Typography>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ position: "relative", left: "40px", top: "20px" }}>
              <BigCloud width={"180"} height={"62"} />
            </Box>
            <Typography variant={"h2"} sx={{ textAlign: "center" }}>
              {t(
                `funnel.join.${
                  currentUser ? "authenticated" : "simple_header"
                }.title`
              )}
            </Typography>
            <Box
              sx={{
                position: "relative",
                bottom: "80px",
                right: "10px",
              }}
            >
              <RightDialog />
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Rock Salt",
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "-0.4px",
                  position: "relative",
                  bottom: "110px",
                  left: "50px",
                  maxWidth: "120px",
                }}
              >
                {t("funnel.join.simple_header.babbling")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      {currentUser || simpleHeader ? (
        <SimpleHeading />
      ) : (
        <Box
          sx={(theme) => ({
            mb: 9,
            display: "flex",
            alignItems: isMobile ? "center" : "start",
            flexDirection: isMobile ? "column-reverse" : "row",
            p: `60px ${isMobile ? "24px" : "60px"}`,
            borderRadius: 3,
            background: theme.palette.secondary.main,
            justifyContent: "space-between",
            maxWidth: isMobile ? "100%" : "1200px",
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: isMobile ? "-60px" : "0px",
              minWidth: "60%",
              mb: isMobile ? "0px" : "-30px",
            }}
          >
            {isPromotionActive ? (
              <PromotionHeading
                optingForRole={optingForRole}
                coopAgreement={coopAgreement}
              />
            ) : (
              <PublicHeading
                optingForRole={optingForRole}
                coopAgreementCode={coopAgreementCode}
                coopAgreement={coopAgreement}
              />
            )}
          </Box>
          <Icon isMobile={isMobile} isPromotionActive={isPromotionActive} />
        </Box>
      )}
    </>
  );
};
