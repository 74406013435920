// vendor
import { useEffect } from "react";

export default function useScroll({ main, list, catRef, pageRef, isLoading }) {
  useEffect(() => {
    if (isLoading || !pageRef || !list.current) return;

    // Scroll to current page after loading
    const item = list.current.querySelector(`#page_${pageRef}`);
    if (!item) return;

    window.scrollTo({
      top: item.offsetTop,
      left: 0,
      behavior: "instant",
    });
  }, [isLoading, list.current]);

  useEffect(() => {
    if (isLoading) return;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [catRef]);

  useEffect(() => {
    // scroll into page on pageId changes
    if (!(pageRef && list.current && main.current)) return;
    const item = list.current.querySelector(`#page_${pageRef}`);
    if (!item) return;

    window.scrollTo({
      top: item.offsetTop - main.current.parentElement.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, [pageRef]);
}
