import { Form as FinalForm, useFormState } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

export const Form = ({ children, id, initialValues, onSubmit, width }) => {
  return (
    <FinalForm
      // This flag below is used to remove conditional fields from the state
      destroyOnUnregister
      mutators={{
        clearField: (args, state, utils) => {
          utils.changeValue(state, args[0], () => undefined);
        },
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={id} onSubmit={handleSubmit} style={{ width: width }}>
          {children}
        </form>
      )}
    />
  );
};

const Submit = (props) => {
  const { disabled, children, ...rest } = props;
  const { t } = useTranslation();
  const { submitting, validating } = useFormState();
  const isLoading = submitting || validating;

  return (
    <Button type="submit" disabled={isLoading || disabled} {...rest}>
      {isLoading ? t("common.loading") : children}
    </Button>
  );
};

Form.Submit = Submit;
