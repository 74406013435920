import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider } from "components/Divider";
import { useQueryParams } from "hooks/useQueryParams";
import { useStore } from "hooks/useStore";
// TODO Review how show tariff name
// function getTariffLiteral(category, has_landline_phone, bandwidth, data) {}

export const MultiLine = ({ name, price, showCancelIcon, onDeleteLine }) => {
  const wholeNumber = Math.trunc(price);
  const decimalNumber = "," + price.split(".")[1];
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "4px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography variant="body_small">{name}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="body_small_bold">{wholeNumber}</Typography>
            <Typography variant="body_small_bold">{decimalNumber}</Typography>
            <Typography variant="body_small_bold">€ / mes</Typography>
          </Box>
          {showCancelIcon && (
            <IconButton
              aria-label="delete"
              onClick={onDeleteLine}
              color="inherit"
              sx={{ p: 0 }}
            >
              <CancelIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider flexItem />
    </Box>
  );
};

export const Price = ({ price, stackingPrice = false }) => {
  const { t } = useTranslation();
  const wholeNumber = Math.trunc(price);
  const decimalNumber = "," + price.split(".")[1];
  const { is_company } = useQueryParams();
  const { isCompany } = useStore();
  const vat_lit =
    is_company === "true" || !!isCompany
      ? "price_tag.vat_not_included"
      : "price_tag.vat_included";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: stackingPrice ? "column" : "row",
        alignItems: stackingPrice ? "flex-start" : "center",
        gap: stackingPrice ? "0px" : "8px",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
        <Typography variant="h3">{wholeNumber}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: stackingPrice ? "column" : "row",
          }}
        >
          <Typography
            sx={{ mb: stackingPrice ? "-8px" : "0px" }}
            variant={stackingPrice ? "body_small_bold" : "body_bold"}
          >
            {decimalNumber}
          </Typography>
          <Typography variant={stackingPrice ? "body_small_bold" : "body_bold"}>
            € / mes
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{ mt: stackingPrice ? "-4px" : "0px" }}
        variant={stackingPrice ? "body_small" : "body"}
      >
        {t(vat_lit)}
      </Typography>
    </Box>
  );
};

const TotalPrice = ({ onContract, isContractDisabled, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        pl: "16px",
        alignItems: "center",
        gap: "8px",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Price {...props} />
      <Button
        onClick={onContract}
        disabled={isContractDisabled}
        endIcon={<ArrowForwardIcon />}
      >
        {t("common.contract")}
      </Button>
    </Box>
  );
};

export const PricePreview = ({
  tariffs,
  isMultiLine,
  minimumLines,
  onDeleteTariff,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        p: isMobile
          ? "8px 16px 24px 16px"
          : isMultiLine
          ? "16px 48px 24px 48px"
          : "16px 16px 24px 48px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderTop: `2px solid ${theme.palette.grey[900]}`,
        background: theme.palette.grey[50],
      })}
    >
      {isMultiLine ? (
        tariffs.map(({ __id, type, name, price }, index) => (
          <MultiLine
            name={name}
            price={parseFloat(price).toFixed(2)}
            showCancelIcon={index + 1 > minimumLines}
            key={__id}
            onDeleteLine={() => onDeleteTariff(__id, type)}
            {...props}
          />
        ))
      ) : (
        <Typography variant="body">{tariffs[0].name}</Typography>
      )}
      <TotalPrice
        price={tariffs
          .reduce((total, { price }) => total + parseFloat(price), 0)
          .toFixed(2)}
        stackingPrice={isMobile}
        {...props}
      />
    </Box>
  );
};
