import { useTranslation } from "react-i18next";
import { TariffCards } from "./Join/components/tariffCards";

export const Companies = () => {
  const { i18n } = useTranslation();
  function resolveCompanyUrl(target) {
    const url = window.location.href;
    const lang = i18n.language;
    switch (target) {
      case "mobile":
        return `${url}${lang === "ca" ? "mobil" : "movil"}`;
      case "internet_and_mobile":
        return `${url}${lang === "ca" ? "internet-mobil" : "internet-movil"}`;
      default:
        return `${url}internet`;
    }
  }
  return <TariffCards getTo={(to) => resolveCompanyUrl(to)} />;
};
