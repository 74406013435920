import { Box, Typography, useMediaQuery } from "@mui/material";
import { Form } from "components/Form";
import { Link } from "components/Link";
import FormSucces from "components/layouts/FormSucces";
import { CheckField } from "components/fields/CheckField";
import { SelectField } from "components/fields/SelectField";
import { InputField } from "components/fields/InputField";
import { postCheckCoverageForm } from "lib/api/tickets";
import { states } from "lib/domain/somconnexio/selections";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { composeValidators, required, validPhone } from "lib/form/validators";
import { Trans, useTranslation } from "react-i18next";
import React, { useState } from "react";
import { theme } from "theme";

const Coverage = () => {
  const { t, i18n } = useTranslation();
  const privacyUrl = t("urls.privacy");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formSuccesData, setFormSuccesData] = useState({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const roadTypes = [
    {
      label: t("check_coverage_form.road_types.street"),
      value: t("check_coverage_form.road_types.street"),
    },
    {
      label: t("check_coverage_form.road_types.avenue"),
      value: t("check_coverage_form.road_types.avenue"),
    },
    {
      label: t("check_coverage_form.road_types.promenade"),
      value: t("check_coverage_form.road_types.promenade"),
    },
    {
      label: t("check_coverage_form.road_types.passage"),
      value: t("check_coverage_form.road_types.passage"),
    },
    {
      label: t("check_coverage_form.road_types.path"),
      value: t("check_coverage_form.road_types.path"),
    },
    {
      label: t("check_coverage_form.road_types.route"),
      value: t("check_coverage_form.road_types.route"),
    },
    {
      label: t("check_coverage_form.road_types.highway"),
      value: t("check_coverage_form.road_types.highway"),
    },
    {
      label: t("check_coverage_form.road_types.crossroad"),
      value: t("check_coverage_form.road_types.crossroad"),
    },
    {
      label: t("check_coverage_form.road_types.square"),
      value: t("check_coverage_form.road_types.square"),
    },
    {
      label: t("check_coverage_form.road_types.roundabout"),
      value: t("check_coverage_form.road_types.roundabout"),
    },
    {
      label: t("check_coverage_form.road_types.ring_road"),
      value: t("check_coverage_form.road_types.ring_road"),
    },
  ];

  const contactLanguages = [
    {
      label: t("check_coverage_form.contact_language.spanish"),
      value: "es_ES",
    },
    {
      label: t("check_coverage_form.contact_language.catalan"),
      value: "ca_ES",
    },
  ];

  const initialValues = {
    surnames: "",
    phone: "",
    block: "",
    doorway: "",
    floor: "",
    scale: "",
    door: "",
    other_information: "",
    zip_code: "",
    contact_lang: `${i18n.language}_ES`,
  };

  const handleSubmit = async (values) => {
    const data = Object.keys(initialValues).reduce((acc, key) => {
      acc[key] = typeof values[key] === "undefined" ? "" : values[key];
      return acc;
    }, {});
    Object.assign(data, values);
    try {
      await postCheckCoverageForm({
        ...data,
        lang: values.contact_lang,
      });
      setFormSuccesData({ name: values.name, phone: values.phone });
      setIsFormSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isFormSubmitted) {
    return (
      <FormSucces
        title={t("succes_form.thank_you_title", { name: formSuccesData.name })}
        message={<Trans i18nKey="succes_form.message_without_phone" />}
        phone={formSuccesData.phone}
        type="fullDisplay"
        onClose={() => setIsFormSubmitted(false)}
        companyMessage={t("succes_form.company_message")}
        buttonLabel={t("succes_form.close_button_coverage")}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: isMobile ? "56px 24px" : "56px 96px",
        gap: "100px",
      }}
    >
      <Form
        id="coverage-form"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        width="100%"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            width: isMobile ? "100%" : "80%",
            paddingBottom: "3rem",
          }}
        >
          <Typography variant="h3" gutterBottom>
            {t("check_coverage_form.title.about_you")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "25px" : "10px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "stretch",
            }}
          >
            <InputField.FormField
              id="name"
              name="name"
              label={t("check_coverage_form.labels.name")}
              sx={{ flex: 1 }}
              validate={required}
            />
            <InputField.FormField
              id="surnames"
              name="surnames"
              label={t("check_coverage_form.labels.surnames")}
              sx={{ flex: 1 }}
              optional
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "25px" : "10px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "stretch",
            }}
          >
            <SelectField.FormField
              id="contact_lang"
              name="contact_lang"
              label={t("check_coverage_form.labels.contact_lang")}
              options={contactLanguages}
              sx={{ flex: 1 }}
              validate={required}
            />
            <InputField.FormField
              id="email"
              name="email"
              label={t("check_coverage_form.labels.email")}
              sx={{ flex: 2 }}
              startIconAdorment={<EmailIcon />}
              validate={required}
            />
            <InputField.FormField
              id="phone"
              name="phone"
              label={t("check_coverage_form.labels.phone")}
              sx={{ flex: 2 }}
              startIconAdorment={<PhoneIcon />}
              mask="000 00 00 00"
              validate={composeValidators(validPhone)}
              optional
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            width: "100%",
            paddingBottom: "2.5rem",
          }}
        >
          <Typography variant="h3" gutterBottom>
            {t("check_coverage_form.title.coverage_data")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "25px" : "10px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "stretch",
            }}
          >
            <SelectField.FormField
              id="road_type"
              name="road_type"
              label={t("check_coverage_form.labels.road_type")}
              options={roadTypes}
              sx={{ flex: 1 }}
              validate={required}
            />
            <InputField.FormField
              id="road_name"
              name="road_name"
              label={t("check_coverage_form.labels.road_name")}
              sx={{ flex: 2 }}
              validate={required}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: isMobile ? "30px" : "10px",
              flexWrap: isMobile ? "wrap" : "nowrap",
              width: "100%",
              justifyContent: "stretch",
              padding: isMobile ? "5px 0 10px 0" : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
              }}
            >
              <InputField.FormField
                id="road_number"
                name="road_number"
                label={t("check_coverage_form.labels.road_number")}
                sx={{ flex: 1 }}
                validate={required}
              />
              <InputField.FormField
                id="block"
                name="block"
                label={t("check_coverage_form.labels.block")}
                sx={{ flex: 1 }}
                optional
              />
            </Box>
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <InputField.FormField
                id="doorway"
                name="doorway"
                label={t("check_coverage_form.labels.doorway")}
                sx={{ flex: 1 }}
                optional
              />
              <InputField.FormField
                id="floor"
                name="floor"
                label={t("check_coverage_form.labels.floor")}
                sx={{ flex: 1 }}
                optional
              />
            </Box>
            <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
              <InputField.FormField
                id="scale"
                name="scale"
                label={t("check_coverage_form.labels.scale")}
                sx={{ flex: 1 }}
                optional
              />
              <InputField.FormField
                id="door"
                name="door"
                label={t("check_coverage_form.labels.door")}
                sx={{ flex: 1 }}
                optional
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: isMobile ? "25px" : "10px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "stretch",
            }}
          >
            <InputField.FormField
              id="other_information"
              name="other_information"
              label={t("check_coverage_form.labels.other_information")}
              sx={{ flex: 1 }}
              optional
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: isMobile ? "30px" : "10px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "stretch",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <InputField.FormField
              id="locality"
              name="locality"
              label={t("check_coverage_form.labels.locality")}
              sx={{ flex: 2 }}
              validate={required}
            />
            <SelectField.FormField
              id="state"
              name="state"
              label={t("check_coverage_form.labels.state")}
              options={states}
              sx={{ flex: 2 }}
              validate={required}
            />
            <InputField.FormField
              id="zip_code"
              name="zip_code"
              label={t("check_coverage_form.labels.zip_code")}
              sx={{ flex: 1 }}
              optional
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            alignItems: "flex-start",
          }}
        >
          <CheckField
            infoLabel={
              <Trans i18nKey="common.therms_data_treatment">
                <Link target="_blank" to={privacyUrl} />
              </Trans>
            }
            onChange={(event) => setIsButtonDisabled(!event.target.checked)}
          />
          <Form.Submit sx={{ width: "358px" }} disabled={isButtonDisabled}>
            {t("common.send")}
          </Form.Submit>
        </Box>
      </Form>
    </Box>
  );
};
export default Coverage;
