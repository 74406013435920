import { useStore } from "./useStore";

const getLinesFromState = (state) => {
  const lineIds = state.lines.map((line) => line.__id);
  const lineEntries = Object.entries(state.formStepDataByKey).filter(
    ([key]) => {
      const lineIdFromKey = (key.match(/^line-(.*)\/.*/) || [])[1];

      return lineIds.includes(lineIdFromKey);
    }
  );

  return Object.values(
    lineEntries.reduce((memo, [key, value]) => {
      const [lineKey] = key.split("/");

      if (!memo[lineKey]) {
        memo[lineKey] = {};
      }

      memo[lineKey] = {
        ...memo[lineKey],
        ...value,
      };

      return memo;
    }, {})
  );
};

export const useLinesFormDerivedState = () => {
  const state = useStore((state) => state);
  const linesInFormData = getLinesFromState(state);
  const internetLineInFormData = linesInFormData.find(
    (line) => line.type === "internet"
  );
  const mobileLinesInFormData = linesInFormData.filter(
    (line) => line.type === "mobile"
  );
  const hasOthersMobileLinesInOffer = (line__id) =>
    mobileLinesInFormData.find(
      (mobileLineInFormData) =>
        mobileLineInFormData.code === "SE_SC_REC_MOBILE_PACK_UNL_20480" &&
        mobileLineInFormData.__id !== line__id
    );

  const getMobileCodeByLineId = (line__id) =>
    mobileLinesInFormData.find((mobileLine) => mobileLine.__id === line__id)
      ?.code;

  return {
    hasOthersMobileLinesInOffer: (line__id) =>
      hasOthersMobileLinesInOffer(line__id),
    internetLineInFormData: internetLineInFormData,
    getMobileCodeByLineId: (line__id) => getMobileCodeByLineId(line__id),
    getLinesFromState: () => getLinesFromState(state),
  };
};
