/*
 * return a list with all unique products in
 * useTariffs.js -> useCatalog() query
 */
export const getAllProducts = ({ tariffs, packs }) => {
  const allProducts = [];
  tariffs.forEach((tariff) => {
    if (tariff?.offer !== null) allProducts.push(tariff.offer);
    allProducts.push(tariff);
  });
  packs.forEach(({ products }) => {
    products.forEach((product) => {
      if (allProducts.find(({ code }) => code === product.code) === undefined) {
        allProducts.push(product);
      }
    });
  });
  return allProducts;
};

/**
 * giving a list of products, filter by mobile and return ordered list without 4 middle products.
 */
export const getExtremeMobileRates = (tariffs) => {
  const extremeMobileRates = tariffs
    .filter((tariff) => tariff.category === "mobile")
    .sort((a, b) => a.data - b.data);
  extremeMobileRates.splice(4, 4);
  return extremeMobileRates;
};
