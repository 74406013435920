import { useStore } from "hooks/useStore";
import { useQueryParams } from "hooks/useQueryParams";
import { RecommendedTariffsPicker } from "../components/recommended/RecommendedTariffsPicker";
import { useState } from "react";
import { ModalContractTariff } from "../components/ModalContractTariff";

export const RecommendedTariffs = ({ packs }) => {
  const [isModalContractOpen, setIsModalContractOpen] = useState(false);
  const { lines, setLines, setIsFiberAssociated, isFiberAssociated } =
    useStore();
  const { bandwidth = 300, landline_phone } = useQueryParams();
  const recommendedPacks = packs.filter(
    ({ fiber_bandwidth, has_landline_phone }) =>
      fiber_bandwidth === Number(bandwidth) &&
      has_landline_phone === Boolean(landline_phone)
  );
  function onClick(matchedPack) {
    if (matchedPack) {
      setIsFiberAssociated(true);
      setLines(matchedPack.products);
      setIsModalContractOpen(true);
    } else {
      setLines([]);
    }
  }
  return (
    <>
      <ModalContractTariff
        isOpen={isModalContractOpen}
        onClose={() => setIsModalContractOpen(false)}
        lines={lines}
        isFiberAssociated={isFiberAssociated}
        isPromotionActive={false}
      />
      <RecommendedTariffsPicker
        recommendedPacks={recommendedPacks.sort((a, b) => a.price - b.price)}
        onClick={onClick}
      />
    </>
  );
};
