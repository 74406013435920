import { Box, Typography, useMediaQuery } from "@mui/material";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import { CardContractInfo } from "../components/packs/CardContractInfo";
import { SuperCard } from "components/SuperCard";
import { MultiLine, Price } from "../components/PricePreview";
import { WLandlineChip, WOPermanenceChip } from "components/Chips";
import { formatBandwith } from "lib/helpers/format";
import { Divider } from "components/Divider";
import { ArrowCurve } from "components/icons/ArrowCurve";
import { BigCloud, SmallCloud } from "components/icons/Clouds";
import { Button } from "components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RoleSelector } from "components/shared/RoleSelector";
import { Forest } from "components/icons/Forest";
import { FloatingCard } from "components/layouts/FloatingCard";

const LateralSummaryWidth = "280px";
const BottomSummaryHeight = "140px";
const ForestSmWidth = "352px";
const ForestSmHeight = "auto";
const ForestHeight = "auto";
const ForestWidth = "756px";

const RoleSummary = ({ ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.grey[50],
        zIndex: 100,
        [theme.breakpoints.up("sm")]: {
          borderLeft: `2px solid ${theme.palette.grey[900]}`,
        },
      })}
    >
      <RoleSelector
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
        buttonsLayout={isMobile ? "row" : "column"}
        {...props}
      />
    </Box>
  );
};

export const ContractTariff = ({ onBack, packs, ...props }) => {
  const { t } = useTranslation();
  const { lines } = useStore();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isBottomSummary = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  const BackButton = () => (
    <Box
      sx={{
        position: "relative",
        width: "0px",
        height: "0px",
        bottom: "8px",
        zIndex: 4,
      }}
    >
      <Button
        variant="transparent"
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
      >
        {isMobile ? "" : t("common.back")}
      </Button>
    </Box>
  );

  function getContent() {
    /**
     * Packs info
     */
    if (packs) {
      const BALine = lines.find((line) => line.category !== "mobile");
      const pack = packs.find(
        (pack) =>
          pack.has_landline_phone === BALine.has_landline_phone &&
          pack.fiber_bandwidth === BALine.bandwidth &&
          pack.mobiles_in_pack ===
            lines.filter((line) => line.category === "mobile").length
      );
      return <CardContractInfo pack={pack} />;
    }
    /**
     * Multi line info
     */
    if (lines.length > 1) {
      return (
        <SuperCard>
          {lines.map(({ __id, name, price }) => (
            <MultiLine
              name={name}
              price={parseFloat(price).toFixed(2)}
              key={__id}
            />
          ))}
          <Box sx={{ ml: "16px" }}>
            <Price
              price={lines
                .reduce((total, { price }) => total + parseFloat(price), 0)
                .toFixed(2)}
              stackingPrice={true}
            />
          </Box>
        </SuperCard>
      );
      /**
       * Single line info
       */
    } else {
      const line = lines[0];
      const isInternet = line.category !== "mobile";
      const title = isInternet
        ? t(
            `funnel.tariffs.internet.offers.${line.category}${
              line.has_landline_phone ? "landline" : ""
            }`
          )
            .split("<br/>")
            .join("")
        : `${t("funnel.shared.category.mobile")} ${
            line.minutes < 99999 ? line.minutes + " min" : t("common.unlimited")
          } + ${line.data / 1024}GB`;
      return (
        <SuperCard>
          <Typography variant="body_xl_bold">{title}</Typography>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "4px" }}>
            {line.has_landline_phone && <WLandlineChip />}
            <WOPermanenceChip />
          </Box>
          {isInternet && (
            <Typography variant="body_bold">
              {formatBandwith(
                line.category === "fiber" ? line.bandwidth : line.data
              )}
            </Typography>
          )}
          <Typography variant="body_small">
            {isInternet
              ? t(`funnel.tariffs.internet.contract_info.info.${line.code}`)
              : t(`funnel.tariffs.mobile.contract_info.${line.code}`)}
          </Typography>
          <Divider sx={{ mt: "-1rem" }} flexItem />
          <Price
            price={line.price.toFixed(2).toString()}
            stackingPrice={true}
          />
        </SuperCard>
      );
    }
  }
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isBottomSummary
            ? "auto"
            : `auto ${LateralSummaryWidth}`,
          gridTemplateRows: isBottomSummary
            ? `auto ${BottomSummaryHeight}`
            : "auto",
          height: "100%",
        }}
      >
        <Box
          sx={(theme) => ({
            bgcolor: "secondary.main",
            px: isMobile ? 3 : 7,
            py: isMobile ? 3 : 5,
            borderBottom: `${isMobile ? "6px" : "16px"} solid ${
              theme.palette.grey[900]
            }`,
            position: "relative",
          })}
        >
          <BackButton />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              alignItems: "center",
              position: "relative",
              zIndex: 3,
            }}
          >
            <Typography variant={isMobile ? "h4" : "h3"}>
              {t("funnel.tariffs.modal_contract.title")}
            </Typography>

            <FloatingCard
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {getContent()}
              {isBottomSummary && (
                <Box
                  sx={{
                    position: "relative",
                    top: "30px",
                  }}
                >
                  <ArrowCurve variant="dashed" rotate={89} />
                </Box>
              )}
              <Box
                sx={{
                  position: "relative",
                  bottom: isBottomSummary ? "20px" : "-50px",
                  left: isBottomSummary ? "30%" : "40%",
                }}
              >
                <BigCloud />
                <Box
                  sx={{
                    position: "absolute",
                    top: "20px",
                    right: "92px",
                  }}
                >
                  <SmallCloud />
                </Box>
              </Box>
            </FloatingCard>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "-8px",
              left: 0,
              right: 0,
            }}
          >
            <Box sx={{ width: "fit-content", margin: "auto" }}>
              <Forest
                width={isMobile ? ForestSmWidth : ForestWidth}
                height={isMobile ? ForestSmHeight : ForestHeight}
              />
            </Box>
          </Box>
        </Box>

        <RoleSummary {...props} />
      </Box>
    </>
  );
};
