import { Trans, useTranslation } from "react-i18next";

const { formatBandwith, formatDataPlanSize } = require("lib/helpers/format");

export const InternetTariffTitle = ({
  category,
  bandwidth,
  data,
  addLandlineInfo = false,
  hasLandline,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {t(
        `funnel.tariffs.internet.contract_info.title_${category}`,
        category === "fiber"
          ? {
              bandwidth: formatBandwith(bandwidth),
              landlineInfo: addLandlineInfo
                ? hasLandline
                  ? t("common.w_landline")
                  : t("common.wo_landline")
                : "",
            }
          : { data: formatBandwith(data) }
      )}
    </>
  );
};

export const PackTariffTitle = ({
  mobilesInPack,
  fiberBandwidth,
  addLandlineInfo = false,
  hasLandline,
  isSharedLines,
  mobileData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {t(
        `funnel.tariffs.internet_and_mobile.contract_info.${
          isSharedLines ? "shared." : ""
        }title`,
        {
          count: mobilesInPack,
          bandwidth: formatBandwith(fiberBandwidth).replace(/ Mb$/gm, ""),
          data: formatDataPlanSize(mobileData),
          landlineInfo: addLandlineInfo
            ? hasLandline
              ? t("common.w_landline")
              : t("common.wo_landline")
            : "",
        }
      )}
    </>
  );
};

export const MobileTariffTitle = ({ minutes, data }) => {
  const { t } = useTranslation();
  function getMinutesLit() {
    return `${minutes < 99999 ? minutes + " min" : t("common.unlimited")}`;
  }
  return (
    <Trans>
      {t("funnel.tariffs.mobile.tariff_info", {
        data: data / 1024,
        minutes: getMinutesLit(),
      })}
    </Trans>
  );
};
