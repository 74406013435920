export const openUrl = (url, target = "_parent") => {
  // On form builds, the context where react is running has a different URL from that where
  // somoffice is hosted. To fill this gap, we have to get the correct origin from the axios api
  // URL configuration, and then, append the route as the pathname to the api origin to get
  // the correct URL.

  if (process.env.REACT_APP_FORM_BUILD) {
    // If is development mode, the public URL and the api URL is the same
    // local.somoffice-sc.coop:3000 and we can enable navigations in the
    // same context if we prepend the hash to the target route.
    const root = process.env.NODE_ENV === "production" ? "/" : "/#/";
    // use parseUrl to discard origin and get only the route pathname.
    const { pathname, search } = parseUrl(url);
    url =
      new URL(window.axios.defaults.baseURL).origin +
      root +
      pathname.replace(/^\//, "") +
      search;
  }

  if (window.Cypress) {
    window.location = url;
  } else if (process.env.NODE_ENV === "production") {
    // Target is now dummy because somoffice does not run anymore below an iframe
    window.open(url, target);
  } else {
    // On development mode, replace the URL and force a reload to wipe the context and simulate
    // a full navigation.
    window.history.replaceState({ from: window.location.href }, null, url);
    window.location.reload();
  }
};

export const getUrlQuery = (url, queryParams = {}) => {
  const searchParams = Object.keys(queryParams)
    .map((key) => {
      return queryParams[key]
        ? `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        : null;
    })
    .filter((d) => d)
    .join("&");

  if (searchParams.length === 0) return url;
  else return `${url}?${searchParams}`;
};

export const getLoginUrl = (
  lines = [],
  isFiberAssociated = false,
  isPromotionActive = false,
  isCompany = false
) => {
  const host =
    process.env.REACT_APP_FORM_BUILD === "1"
      ? new URL(window.axios.defaults.baseURL).origin
      : window.location.origin;
  return `${host}/user/signup?redirect_to=${encodeURIComponent(
    getSignupUrl(
      lines,
      undefined,
      undefined,
      true,
      isFiberAssociated,
      false,
      isPromotionActive,
      isCompany
    )
  )}`;
};

export const getSignupUrl = (
  lines = [],
  optingForRole = undefined,
  coopAgreementCode = undefined,
  isNewSignupFlow = true,
  isFiberAssociated = false,
  utm_campaign = false,
  isPromotionActive = false,
  isCompany = false
) => {
  const serializedIntent = JSON.stringify({
    isFiberAssociated,
    isPromotionActive,
    isCompany,
    optingForRole,
    isNewSignupFlow,
    coopAgreementCode,
    lines,
  });

  return propagateLocaleParam(
    `/signup/data?intent=${encodeURIComponent(
      serializedIntent
    )}&utm_campaign=${utm_campaign}`
  );
};

export const getJoinUrl = (
  preferredLocale = "ca",
  optingForRole = undefined,
  coopAgreementCode = undefined,
  isUserCompany = false
) => {
  const protocolSomOffice = window.location.protocol;
  const hostSomOffice = window.location.hostname;
  const portSomOffice = hostSomOffice.startsWith("local") ? ":3000" : "";
  const isCompany = isUserCompany ? "&is_company=true" : "";
  const role = optingForRole ? `&opting_for_role=${optingForRole}` : "",
    agreementCode =
      optingForRole && coopAgreementCode
        ? `&coop_agreement_code=${coopAgreementCode}`
        : "";

  return `${protocolSomOffice}//${hostSomOffice}${portSomOffice}/join?locale=${preferredLocale}${role}${agreementCode}${isCompany}`;
};

/**
 * If locale is set int the url, we need to propagate to the destination url (if
 * it is a relative url)
 */
export const propagateLocaleParam = (to) => {
  const isExternalLink = Boolean(to.match(/^https?:\/\/|^mailto:/));

  let currentQuery;
  if (process.env.REACT_APP_FORM_BUILD === "1") {
    [, currentQuery] = window.location.hash.split("?");
  } else {
    currentQuery = window.location.search;
  }

  const localeFromUrl = new URLSearchParams(currentQuery).get("locale");

  if (isExternalLink || !localeFromUrl) return to;

  const [, queryString] = to.split("?");
  const params = new URLSearchParams(queryString);
  params.set("locale", localeFromUrl);

  return to.replace(/\?.*$/, `?${params.toString()}`);
};

export function typeToCollection(type) {
  if (type === "personalized") {
    return [{ type: "internet" }, { type: "mobile" }];
  }

  return [{ type }];
}

export function parseUrl(url) {
  const isURL =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
      url
    );

  if (!isURL) {
    url =
      process.env.REACT_APP_API_URL.replace(/\/api\/?$/, "") +
      "/" +
      url.replace(/^\//, "");
  }

  return new URL(url);
}
