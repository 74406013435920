import { Box, useMediaQuery } from "@mui/material";

export const FloatingCard = ({ sx, children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        maxWidth: isMobile ? "100%" : "550px",
        m: "0 auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
