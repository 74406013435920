import { TopBar } from "components/TopBar";
import { Trans, useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";
import { useCoopAgreement } from "hooks/queries/useCoopAgreement";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout.js";
import { Tiles } from "components/layouts/Tiles";
import { Box, useMediaQuery } from "@mui/material";
import { Link } from "components/Link";
import { Spinner } from "components/Spinner";
import { Heading } from "./components/Heading.js";
import { useApplicationContext } from "hooks/useApplicationContext.js";
import { Member } from "./components/Member.js";
import { TariffCards } from "./components/tariffCards.js";

const Container = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        p: `60px ${isMobile ? "24px" : "96px"}`,
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};
const Content = ({
  exclusiveTariffs = false,
  optingForRole,
  coopAgreementCode,
  coopAgreement,
  isPromotionActive,
}) => {
  const { currentUser } = useApplicationContext();
  const showMember =
    !exclusiveTariffs && !Boolean(currentUser) && !Boolean(optingForRole);
  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Heading
          simpleHeader={exclusiveTariffs}
          optingForRole={optingForRole}
          coopAgreementCode={coopAgreementCode}
          coopAgreement={coopAgreement}
          isPromotionActive={isPromotionActive}
        />
      </Box>
      <TariffCards />
      {showMember && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Member />
        </Box>
      )}
    </Container>
  );
};

export const Join = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    opting_for_role: optingForRole,
    coop_agreement_code: coopAgreementCode,
  } = useQueryParams();
  const searchCoopAgreement =
    optingForRole === "coop_agreement" && coopAgreementCode !== "SC";
  const {
    data: { name: coopAgreement, first_month_promotion: isPromotionActive } = {
      name: null,
    },
    isLoading,
  } = useCoopAgreement({
    automatically_run: searchCoopAgreement,
    coopAgreementCode,
  });

  if (isLoading) {
    return (
      <FullScreenCenteredLayout>
        <Spinner />
      </FullScreenCenteredLayout>
    );
  }

  if (searchCoopAgreement && !coopAgreement) {
    return (
      <FullScreenCenteredLayout>
        <Tiles columns={1}>
          <Box mb={2}>
            <Trans i18nKey="funnel.join.coop_agreement.error">
              <Link
                target="_blank"
                to={"mailto:" + t("common.assistance_email")}
              />
            </Trans>
          </Box>
        </Tiles>
      </FullScreenCenteredLayout>
    );
  }

  return (
    <TopBar>
      <Content
        optingForRole={optingForRole}
        coopAgreementCode={coopAgreementCode}
        coopAgreement={coopAgreement}
        isPromotionActive={isPromotionActive}
        {...props}
      />
    </TopBar>
  );
};
